%upcaret {
  width: 0;
  height: 0;
  border-left: 0.33rem solid transparent;
  border-right: 0.33rem solid transparent;
  top: -0.5rem;
  left: 1rem; }
.nav {
  color: $haze;
  display: flex;
  justify-content: space-between;
  @include content;
  &_active {
    background-color: rgba($light, 0.05);
    border-radius: 0.25rem; }
  &, &_body {}
  &_icon {
    width: 0.7rem;
    margin-left: 0.33rem;
    transition: transform 0.3s var(--ease);
    transform-origin: 50% 50%; }
  &_body {
    display: flex;
    flex: 1; }
  &_header {
    background-color: $bg;
    padding: 0 1.5rem;
    position: fixed;
    width: 100%;
    z-index: 99;
    left: 0; }
  &_item {
    padding: 0.5rem 1rem;
    display: inline-flex;
    align-items: center; }
  &_sub {
    width: 100%;
    left: 0;
    position: absolute;
    z-index: 20;
    border-radius: 0 0 0.5rem 0.5rem;
    top: 3rem;
    transition: height 0.3s ease-in-out;
    height: 0;
    overflow: hidden;
    padding: 0.96rem 0 0;
    background: transparent; }

  &_parent {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0.25rem 0;
    border-radius: 0.5rem; }

  &_open + &_sub &_child {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    z-index: 3;
    & + a {
      @media screen and (min-width: $mobile-menu-breakpoint) {
        padding-top: 0; } }
    &:not(:first-child) {
      position: relative; }
    &:first-child::before {
      position: absolute;
      content: "";
      @extend %upcaret;
      border-bottom: 0.5rem solid var(--translucent-light);
      z-index: 2; } }
  &_open + &_sub {
    height: initial;
    z-index: 999;
    overflow: initial;
    border-radius: 0.5rem;
    padding-bottom: 0.5rem;
    @media screen and (min-width: $mobile-menu-breakpoint) {
      color: var(--text); }
    &::before, &::after {
      content: "";
      position: absolute; }
    &::before {
      @extend %upcaret;
      z-index: 1;
      @media screen and (min-width: $mobile-menu-breakpoint) {
        border-bottom: 0.5rem solid var(--choice-bg); } }
    &::after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--translucent-light);
      border-radius: 0.5rem;
      box-shadow: 0 1rem 3rem rgba(0,0,0,0.25); }
    @media screen and (min-width: $mobile-menu-breakpoint) {
      background: var(--choice-bg);
      width: auto;
      white-space: nowrap; } }
  &_open &_icon {
    transform: rotate(180deg); }

  &_sub &_item {
    &:not(.nav_child) {
      position: relative; }
    z-index: 5;
    display: block;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    transition: background 0.3s ease-out;
    margin: 0; }

  &_brand {
    img {
      max-width: 15rem;
      margin: 0; }
    @media screen and (min-width: $mobile-menu-breakpoint) {
      padding-left: 0;
      // margin-left: -16rem
      // transform: translateX(50rem)
      img {
        background: transparent !important; } } }

  &_center {
    display: none;
    @media screen and (min-width: $mobile-menu-breakpoint) {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      + .follow {
        flex: initial; } } }

  &_hide {
    @media screen and (min-width: $mobile-menu-breakpoint) {
      display: none; } }
  &_close {
    display: none; } }

.content, .footer {
  padding: 1.5rem;
  @media screen and (min-width: 1441px) {
    padding: 1.5rem 0; } }

.content {
  padding-top: 5rem;
  @include content; }

.footer {
  // border-top: 1px solid $xhaze
  background: var(--footer-bg);
  margin-top: 2rem;
  font-size: 0.9rem;
  &_inner {
    @include content;
    display: grid;
    grid-template-columns: 4rem 1fr;
    align-items: center;
    position: relative; } }

.button {
  background-color: $theme;
  color: $light;
  display: inline-flex;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  border: 1px solid $theme;
  border-radius: 1.5rem;
  font-size: 0.9rem;
  align-items: center;
  user-select: none;
  &:hover, &:focus {
    background-color: darken($theme, 20%);
    color: $light;
    border: 1px solid darken($theme, 20%); }

  &_translucent {
    background-color: rgba($theme, 0.15);
    color: $theme;
    border: 1px solid transparent; }
  &_tally {
    padding: 0 0.75rem;
    border-radius: 0.5rem;
    background-color: $theme;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $light;
    margin: 0.1rem 0 0.1rem 0.2rem;
    font-size: 0.7rem; } }

.post {
  &_link, &_title {
    margin-bottom: 0; }
  &_link {
    line-height: 1;
    > a {
      display: block;
      line-height: 1.35; } }
  &s {
    margin-top: 2rem; }
  &_header {
    height: 50vh;
    max-height: 35rem;
    background-color: $bg;
    // background-image: url(/images/island.jpg)
    background-size: cover;
    background-position: center;
    margin-top: 4.2rem;
    @media screen and (max-width: 992px) {
      height: 40vh;
      margin-top: 3rem; }
    @media screen and (max-width: 667px) {
      height: 30vh; }
    & + .content {
      padding-top: 0; } }
  &_item {
    // box-shadow: 0  2.25rem 1.25rem -1.25rem var(--shadow)
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    background: var(--post-bg); }
  &_tag {
    padding: 0.2rem 0.8rem;
    font-size: 0.8rem;
    &s {
      display: none;
      &.jswidgetopen {
        display: initial;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        overflow-y: auto;
        width: 100vw;
        padding: 5rem 1.5rem;
        background: var(--translucent-light);
        cursor: pointer;
        z-index: 999;
        overflow-y: auto; }
      &_toggle {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        cursor: pointer; } } }
  &_tag, &_share {
    margin: 0.5rem; }
  &_share {
    display: inline-flex; }
  &_meta {
    align-items: stretch;
    &, span {
      display: inline-flex;
      flex-flow: row wrap; }
    span {
      align-items: center;
      margin-bottom: 0.5rem;
      &:last-of-type {
        @media screen and (max-width: 667px) {
          margin-top: -0.5rem; } } }

    .button {
      @media screen and (min-width: 667px) {
        margin: 0 0.5rem; } } }

  &_date {
    margin-right: 0.5rem;
    margin-left: 0.25rem; }
  &_featured {
    display: block;
    margin: 1rem auto; }
  &_content {
    a {
      color: $theme; }
    ul, ol {
      list-style: initial;
      padding: 0.5rem 1.25rem;
      li {
        padding-top: 0.25rem; } }
    ol {
      list-style: decimal; } } }

.scrollable {
  display: grid;
  width: 100%;
  max-width: 100%;
  overflow-x: auto; }

.to-top {
  position: fixed;
  bottom: 2rem;
  @media screen and (max-width: 667px) {
    bottom: 2.25rem; }
  right: 1.5rem;
  height: 2.25rem;
  width: 2.25rem;
  background-color: $theme;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  -webkit-appearance: none;
  border-radius: 50%;
  color: $light !important;
  text-decoration: none !important;
  font-size: 1.25rem;
  cursor: pointer;
  &, &:focus {
    outline: none; } }

.mb-0 {
  margin-bottom: 0 !important; }

.tag {
  &s {
    &_list {
      cursor: initial;
      background: var(--choice-bg);
      padding: 4.5rem 1.5rem 1.5rem 1.5rem;
      border-radius: 1rem;
      max-width: 720px;
      margin: 0 auto;
      box-shadow: 0 0.5rem 1.5rem rgba(0,0,0,0.12);
      position: relative;
      display: flex;
      flex-flow: row wrap; }
    &_nav {
      position: relative; }
    &_hide {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      padding: 0.5rem;
      border-radius: 50%;
      cursor: pointer;
      @media screen and (min-width: 992px) {
        display: none; } }
    &_sort {
      font-size: 1rem;
      color: var(--light);
      background: var(--theme);
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      border-radius: 1.5rem;
      padding: 0.1rem;
      &, span {
        user-select: none; }
      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 2rem;
        position: relative;
        z-index: 5;
        cursor: pointer;
        width: 5rem;
        font-weight: 500; }
      &::before {
        content: "";
        position: absolute;
        width: 4.5rem;
        top: 0.25rem;
        bottom: 0.25rem;
        left: 0.25rem;
        z-index: 3;
        background: var(--bg);
        opacity: 0.5;
        border-radius: 1.5rem;
        transition: 0.25s transform var(--ease); }
      &.sorted {
        &::before {
          transform: translateX(5rem); } } } }
  &-title {
    border-bottom: none !important;
    display: inline-block !important;
    position: relative;
    font-size: 2rem;
    margin-bottom: -1rem;
    &::after {
      content: attr(data-count);
      margin-left: 1.5rem;
      background-color: #eee;
      padding: 0.25rem 1rem;
      border-radius: 15%;
      font-size: 1.5rem; } } }

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  &, img, svg {
    width: 1.1rem;
    height: 1.1rem; }
  &_2 {
    width: 2.2rem;
    height: 2.2rem; } }
.link {
  opacity: 0;
  position: relative;
  &_yank {
    opacity: 1;
    &ed {
      position: absolute;
      right: -1rem;
      top: -2rem;
      background-color: $theme;
      color: $light;
      width: 7rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.9rem;
      border-radius: 1rem;
      text-align: center;
      &::after {
        position: absolute;
        top: 1rem;
        content: "";
        border-color: $theme transparent;
        border-style: solid;
        border-width: 1rem 1rem 0 1rem;
        height: 0;
        width: 0;
        transform-origin: 50% 50%;
        transform: rotate(145deg);
        right: 0.45rem; } } } }

.excerpt {
  &_header, &_footer {
    padding: 1rem; }
  &_footer {
    padding: 0 1rem 2.25rem 1rem; }
  &_thumbnail {
    min-height: 10rem;
    display: none;
    @media screen and (min-width: $mobile-menu-breakpoint) {
      display: block;
      border-radius: 0.5rem; } }
  &_footer {
    &.partition {
      display: grid;
      @media screen and (min-width: $mobile-menu-breakpoint) {
        grid-template-columns: 2fr 7fr;
        grid-gap: 1rem; } } } }
.sidebar {
  &_inner {
    position: relative;
    &::before {
      content: "";
      padding: 0.5px;
      top: 0;
      bottom: 0;
      // background: var(--light)
      background: linear-gradient(to bottom, var(--haze), var(--light), var(--haze));
      position: absolute;
      left: -2rem {
 } } } }        // display: none

.author {
  &_header {
    display: grid;
    grid-template-columns: 3rem 1fr;
    grid-gap: 1rem; }
  &_bio {
    a {
      color: $theme; } } }

.pagination {
  display: flex; }

.page {
  &-item {
    padding: 0.2rem;
    &.disabled {
      opacity: 0.7; }
    &:first-child, &:last-child {
      display: none; }
    &.active a {
      background-color: darken($theme, 20%); } }
  &-link {
    padding: 0.25rem 0.75rem;
    background-color: $theme;
    color: $light;
    border-radius: 1rem; }
  &_only {
    display: none !important; }
  & &_only {
    display: initial !important; } }

.round {
  border-radius: 50%;
  max-width: 100%;
  height: auto;
  padding: 0;
  vertical-align: middle; }

.float {
  &_left {
    float: left;
    margin-right: 1rem;
    + p {
      padding-top: 0; } }
  &_right {
    float: right;
    margin-left: 1rem; }
  &_left, &_right {
    &::after {
      clear: both; } } }

.follow {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  svg {
    fill: $haze;
    margin-left: 0.75rem; } }

figcaption {
  font-style: italic;
  opacity: 0.67;
  font-size: 0.9rem; }

.to {
  &_top {
    background-color: $theme;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: 99;
    &.ios {
      position: absolute;
      bottom: 0.75rem;
      right: 0; }
    &:hover {
      background-color: $theme; }
    svg {
      fill: $light;
      opacity: 0.5;
      transition: 0.3s opacity var(--ease); }
    &:hover svg {
      opacity: 1; } } }

.color {
  &_mode {
    // width: 3rem
    height: 1.5rem;
    display: grid;
    align-items: center;
    margin: 0 0.5rem;
    @media screen and (min-width: $mobile-menu-breakpoint) {
      margin: 0 1.5rem;
      grid-template-columns: 1fr; } }

  &_choice {
    width: 3rem;
    background-color: var(--translucent-light);
    border-radius: 1rem;
    height: 1.5rem;
    outline: none;
    border: none;
    -webkit-appearance: none;
    cursor: pointer;
    position: relative;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0.25rem 1rem rgba(0,0,0,0.15);
    &::after {
      content: "";
      position: absolute;
      top: 0.1rem;
      left: 0.1rem;
      width: 1.3rem;
      height: 1.3rem;
      background-image: url("#{$images}sun.svg");
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      z-index: 2; } }
  &_animate {
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    &::after {
      transition: transform 0.5s cubic-bezier(.19,1,.22,1);
      will-change: transform; } } }

.taxonomy {
  text-transform: capitalize; }

.image {
  &-scale {
    position: fixed;
    z-index: 999999;
    left: 0;
    right: 0;
    height: 100vh;
    top: 0;
    padding: 1.5rem;
    background-color: var(--bg);
    display: grid;
    align-items: center;
    overflow: auto; }
  &-scale &-scalable {
    background-color: var(--text); }
  &-scalable {
    cursor: pointer;
    transition: transform 0.3s var(--ease); }
  &_featured {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important; }
  &_thumbnail {
    margin: 0; } }

.video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: 1.5rem 0;
  border-radius: 0.6rem;
  background-color: var(--bg);
  box-shadow: 0 1rem 2rem rgba(0,0,0,0.17);
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: none;
    position: absolute;
    transform: scale(1.02); } }

.notices {
  border-top-width: 2rem;
  border-top-style: solid;
  color: #666;
  margin: 2rem 0;
  padding-bottom: .1px;
  padding-left: 1rem;
  padding-right: 1rem;
  .label {
    color: #fff;
    margin-top: -1.75rem;
    font-weight: bold;
    &::first-child::before {
      font-weight: 900;
      margin-left: -.35rem;
      margin-right: .35rem; } }
  &.info {
    border-color: var(--notice-info-border-color);
    background: var(--notice-info-background); }
  &.warning {
    border-color: var(--notice-warning-border-color);
    background: var(--notice-warning-background); }
  &.image-warning {
    margin: 0; }
  &.note {
    border-color: var(--notice-note-border-color);
    background: var(--notice-note-background); }
  &.tip {
    border-color: var(--notice-tip-border-color);
    background: var(--notice-tip-background); }
  .highlight_wrap {
    background: var(--notice-code-bg) !important; } }
